import {
  toValue,
  tryOnScopeDispose,
  type MaybeRef,
  type MaybeRefOrGetter,
} from '@vueuse/shared'
import type { Ref } from 'vue'

export interface ReactiveRouteOptions {
  /**
   * Mode to update the router query, ref is also acceptable
   *
   * @default 'replace'
   */
  mode?: MaybeRef<'replace' | 'push'>

  /**
   * Route instance, use `useRoute()` if not given
   */
  route?: ReturnType<typeof useRoute>

  /**
   * Router instance, use `useRouter()` if not given
   */
  router?: ReturnType<typeof useRouter>
}

export interface ReactiveRouteOptionsWithTransform<V, R>
  extends ReactiveRouteOptions {
  /**
   * Function to transform data before return
   */
  transform?: (val: V) => R
}

const _cache = new WeakMap()

export function useRouteQuery<
  T extends Record<string, any> = Record<string, any>,
>(options?: ReactiveRouteOptions): Ref<T>

export function useRouteQuery<T = any, K = T>(
  name: string,
  defaultValue?: () => T,
  options?: ReactiveRouteOptionsWithTransform<T, K>,
): Ref<K>

export function useRouteQuery<
  T = any,
  K = T,
  R extends Record<string, any> = Record<string, any>,
>(
  nameOrOptions?: string | ReactiveRouteOptions,
  defaultValue?: MaybeRefOrGetter<T>,
  options?: ReactiveRouteOptionsWithTransform<T, K>,
): Ref<R> | Ref<K> {
  if (typeof nameOrOptions === 'string') {
    return _useRouteQueryParam<T, K>(nameOrOptions, defaultValue, options)
  }

  return _useRouteQuery<R>(nameOrOptions)
}

function _useRouteQuery<T extends Record<string, any> = Record<string, any>>(
  options: ReactiveRouteOptions = {},
): Ref<T> {
  const { mode = 'replace', route = useRoute(), router = useRouter() } = options

  if (!_cache.has(route)) _cache.set(route, {})

  const _query: T = _cache.get(route)

  tryOnScopeDispose(() => {
    _cache.delete(route)
  })

  updateInnerQuery(route.query)

  let _trigger: () => void
  const proxyCache = new WeakMap()

  const proxy = customRef<T>((track, trigger) => {
    _trigger = trigger

    return {
      get() {
        track()

        return handler<T>(_query, () => {
          trigger()
          nextTick(() => updateRouteQuery())
        })
      },
      set(v) {
        replaceInnerQuery(v)
        trigger()
        nextTick(() => updateRouteQuery())
      },
    }
  })

  watch(
    () => route.query,
    (v) => {
      replaceInnerQuery(v)
      _trigger()
    },
    { flush: 'sync', deep: true },
  )

  return proxy

  function handler<T extends Record<string, any>>(
    target: T,
    onChange: () => void,
  ) {
    return new Proxy(target, {
      get(target, property: string) {
        const item = target[property]

        if (item && typeof item === 'object') {
          if (proxyCache.has(item)) return proxyCache.get(item)

          const proxy: Record<string, any> = handler(item, onChange)
          proxyCache.set(item, proxy)
          return proxy
        }

        return item
      },
      set(target, property: string, newValue) {
        if (target[property] !== newValue) {
          target[property as keyof T] = newValue
          onChange()
        }

        return true
      },
    })
  }

  function updateInnerQuery(value: Record<string, any>) {
    Object.assign(_query, clone(value))
  }

  function replaceInnerQuery(value: Record<string, any>) {
    for (const key in _query) delete _query[key]
    updateInnerQuery(value)
  }

  function updateRouteQuery() {
    const { params, query, hash } = route

    router[toValue(mode)]({
      params,
      query: clone({ ...query, ..._query }),
      hash,
    })
  }
}

function _useRouteQueryParam<T = any, K = T>(
  name: string,
  defaultValue?: MaybeRefOrGetter<T>,
  options: ReactiveRouteOptionsWithTransform<T, K> = {},
): Ref<K> {
  const { route = useRoute(), transform = (value) => value as any as K } =
    options

  const query = _useRouteQuery(options)
  const _query: Record<string, any> = _cache.get(route)

  const proxyCache = new WeakMap()

  return customRef<K>((track, trigger) => ({
    get() {
      track()

      const data = query.value[name] ?? toValue(defaultValue)
      const transformedData = transform(data)

      if (typeof transformedData !== 'object') return transformedData

      if (typeof query.value[name] !== 'object') {
        _query[name] = data ?? {}
      }

      return handler<any>(query.value[name], transformedData, () => {
        trigger()
      })
    },
    set(value) {
      if (query.value[name] === value) return

      query.value[name] = value

      trigger()
    },
  }))

  function handler<T extends Record<string, any>>(
    source: T,
    transformed: T,
    onChange: () => void,
  ) {
    return new Proxy(source, {
      get(target, property: string) {
        const sourceItem = target[property]
        const transformedItem = transformed[property]

        if (sourceItem && typeof sourceItem === 'object') {
          if (proxyCache.has(sourceItem)) return proxyCache.get(sourceItem)

          const proxy: Record<string, any> = handler(
            sourceItem,
            transformedItem,
            onChange,
          )
          proxyCache.set(sourceItem, proxy)
          return proxy
        }

        return transformedItem
      },
      set(target, property: string, newValue) {
        if (target[property] !== newValue) {
          target[property as keyof T] = newValue
          onChange()
        }

        return true
      },
    })
  }
}

function clone<T extends Record<string, any>>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}
